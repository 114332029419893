*{
    margin: 0;
    padding: 0;
}
.main3 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: white;
  position: relative;
}

.btnDiv{
  position: absolute;
  top: 1rem;
}

.center-box {
  /* background-color: #ffffff; */
  /* border: 1px solid #ddd; */
  padding: 20px;
  text-align: center;
}

.image {
  max-width: 100%;
  max-height: 200px;
}

.content {
  margin-top: 10px;
  font-size: 18px;
}

.btnDiv{
  border: none;
  background: rgb(194, 227, 247);
  color: black;
  border-radius: 3rem;
  padding: .5rem;
  cursor: pointer;
  margin-top: 15px;
  padding: 1rem;
}

.btn {
    border: none;
    background: rgb(26, 137, 206);
    color: white;
    border-radius: 3rem;
    padding: .5rem;
    cursor: pointer;
    margin-top: 15px;
    padding: 1rem;
}

.btnLink {
  text-decoration: none;
  color: white;
}
