* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    font-size: 62.5%;
}

body {
    background: rgb(231, 230, 230);
    color: black;
    font-family: 'Poppins', sans-serif;
}

.main2 {
    max-height: 100vh;
    display: flex;
    gap: 2rem;
}

.card {
    margin-top: 2rem;
    margin-left: 2rem;
    flex: 3;
}
.card2 {
    margin: 2rem;
    flex: 9;
}

.sideBar {
    /* flex: 3; */
    border-right: 1px solid white;
    border-radius: 2rem;
    background: white;
    /* padding: 1rem; */
    height: 92vh;
    /* margin-bottom: .rem; */
}

/* .main {
    flex: 9;
} */

.logo {
    margin-right: 1rem;
}

.brand {
    font-size: 2rem;
}

.upperSide {
    padding: 2.5rem;
    /* border-bottom: 1px solid black; */
    height: 88%;
}

.upperSideTop {
    display: flex;
    align-items: center;
    margin-bottom: 2.5rem;
}

.addBtn {
    height: 2rem;
    padding-right: 1rem;
}

.midBtn {
    background: rgb(26, 137, 206);
    border: none;
    color: white;
    padding: 1.5rem;
    font-size: 1.5rem;
    width: 25rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    margin-bottom: 3rem;
    border-radius: 3rem;
}

.quary {
    display: flex;
    align-items: center;
    /* justify-content: center; */
    background-color: transparent;
    padding: 1.5rem;
    width: 25rem;
    margin: 1rem auto;
    border-radius: 3rem;
    border: 1px solid black;
}

.quary>img {
    margin-right: 2rem;
    object-fit: cover;
}

.quaryImg {
    margin-left: 2rem;
}



/* *********************************************Main********************************* */

.mainDiv {
    min-height: calc(100vh - 14rem);
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 100%;
    width: 77%;
    margin: auto;
    /* margin: 6rem 10rem; */
}

.chatsDiv {
    /* overflow: hidden; */
    overflow-y: scroll;
    scroll-behavior: smooth;
    width: 100%;
    /* max-width: 70rem; */
    height: calc(100vh - 17rem);

}

/* Hide the scrollbar in WebKit browsers (Chrome, Safari) */
.chatsDiv::-webkit-scrollbar {
    width: 0; /* Hide the scrollbar track */
  }
  
  /* Optional: You can also hide the scrollbar thumb (the draggable part) */
  .chatsDiv::-webkit-scrollbar-thumb {
    background: transparent;
  }
  
  /* Optional: You can style the scrollbar track if you want */
  .chatsDiv::-webkit-scrollbar-track {
    background: transparent;
  }
  

.chat {
    margin: 1rem;
    padding: 2rem 3rem;
    font-size: 1.2rem;
    display: flex;
    align-items: flex-start;

    text-align: justify;
}

.chatImg {
    object-fit: cover;
    width: 3.5rem;
    margin-right: 2rem;
    border-radius: 50%;
}

.bot {
    background: white;
    border-radius: 2.5rem;
}

.reGentxtDiv {
    background: white;
    margin-left: 83%;
    border-radius: 3rem;
    width: 15%;
    padding: 1%;
    display: flex;
    align-items: center;
}

.refreshLogo {
    height: 15px;
    margin-right: 10px;
}

.chatFooter {
    margin-top: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.inp {
    padding: 0.5rem;
    background: white;
    opacity: 1;
    display: flex;
    align-items: center;
    border-radius: 3rem;
    width: 100%;
}

.inp>input {
    width: calc(100% - 3rem);
    outline: none;
    padding: 1.25rem;
}

.inp>input,
.send {
    background: transparent;
    border: white;
}

.inp>input,
.sendImg {
    background: transparent;
    border: white;
    margin-right: 1rem;
}

.sendVoice{
    margin-left: 2rem;
    border: none;
    padding: 1rem;
    background: rgb(194, 227, 247);;
    border-radius: 50%;
}
.custom-file-input {
    display: inline-block;
    cursor: pointer;
    padding: 10px 20px;
    background: transparent;
    color: #fff;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    transition: background-color 0.3s ease;
  }
  
  .custom-file-input:hover {
    background: transparent;
  }